/* Ancora styles
   ancorafintech.com
   Ankit Patil
   ankitpatil.com
   10th Jan 2022 */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

@font-face {
  font-family: 'Canela';
  src: url('./assets/Canela-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
  color: #373737;
}

body {
  background: #F8F8F8;
}

.noscroll {
  overflow: hidden !important;
}

#root {
  position: relative;
  width: 100%;
  overflow: hidden;
}

img {
  display: block;
  height: auto;
}

.container {
  width: calc(100% - 30px);
  max-width: 1280px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.col-2 {
  flex: 1 1 50%;
  flex-grow: 0;
}

.col-2:nth-child(odd) {
  padding-right: 15px;
}

.col-2:nth-child(even) {
  padding-left: 15px;
}

.col-60p, .col-40p-r {
  flex: 1 1 60%;
  padding-right: 15px;
}

.col-40p, .col-60p-r {
  flex: 1 1 40%;
  padding-left: 15px;
}

.col-3 {
  flex: 1 1 33.333333333333%;
  max-width: calc(33.333333333333% - 40px);
}

.col-3:not(:last-child) {
  margin-right: 60px;
}

.full-col {
  flex: 1 1 100%;
}

.spread {
  position: relative;
}

.spread::before, .spread::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spread::before {
  background: #0C4B76;
  transform: scale(1.08) rotate(-5.81deg);
  z-index: -1;
}

.spread::after {
  background: #1088D4;
  transform: rotate(5.82deg) translate(-24px, 24px);
  z-index: -2;
}

.text-gradient {
  background: linear-gradient(180deg, #087ACC 0%, #179CF0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient {
  background: linear-gradient(180deg, #087ACC 0%, #179CF0 100%);
}

.offset {
  opacity: 0;
  transform-origin: top left;
  transform: rotate(6deg) scale(.84) translate(-60px, 60px);
  transition: all .6s cubic-bezier(0.22, 1, 0.36, 1);
}

.ready .offset {
  opacity: 1;
  transform: rotate(0deg) scale(1) translate(0px, 0px);
}

.listening {
  opacity: 0;
  transform: rotate(6deg) scale(.84) translate(-60px, 60px);
  transition: all .6s cubic-bezier(0.22, 1, 0.36, 1) .3s;
}

.listening.reveal {
  opacity: 1;
  transform: rotate(0deg) scale(1) translate(0, 0);
}

.listening.delay-0 {

  transition-delay: .45s;
}

.listening.delay-1 {
  transition-delay: .6s;
}

.listening.delay-2 {
  transition-delay: .75s;
}

.text-center {
  text-align: center;
}

.page-transition-enter {
  opacity: 0;
  transform: translateY(180px);
}

.page-transition-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all 3s cubic-bezier(0.83, 0, 0.17, 1);
}

.page-transition-exit {
  opacity: 0;
  transform: translateY(-180px);
}

.page-transition-exit-active {
  opacity: 0;
  transform: translateY(0px);
  transition: opacity 3s cubic-bezier(0.83, 0, 0.17, 1);
}

nav {
  padding: 60px 0 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  z-index: 99999;
  height: 30px;
  overflow: visible;
  background: rgba(248, 248, 248, 0);
  border-bottom: 1px solid rgba(55, 55, 55, 0);
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1);
}

nav::after {
  content: "";
  display: block;
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
  height: calc(100% + 240px);
  opacity: 0;
  z-index: -1;
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1);
  pointer-events: none;
  background: linear-gradient(180deg, #087ACC 0%, #179CF0 100%);
}

nav.open::after {
  opacity: 1;
}

nav.fix {
  padding: 24px 0;
  height: 54px;
  background: rgba(248, 248, 248, 1);
  border-bottom: 1px solid rgba(55, 55, 55, .1);
}

nav.open, nav.fix.open {
  padding: 60px 0;
  background: rgba(248, 248, 248, 0);
  height: 100vh;
  overflow-y: scroll;
}

nav .container {
  justify-content: space-between;
}

nav .logo {
  position: relative;
  height: 37px;
  padding-top: 8px;
  opacity: 0;
  cursor: pointer;
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1) .6s;
}

nav.ready .logo {
  opacity: 1;
}

nav .logo .logo-dark {
  position: absolute;
  top: 8px;
  left: 0;
  opacity: 0;
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1);
}

nav.fix .logo-dark, nav.about .logo-dark, nav.book .logo-dark {
  opacity: 1;
}

nav.fix.open .logo-dark, nav.about.open .logo-dark, nav.book.open .logo-dark {
  opacity: 0;
}

nav .menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav.book .menu {
  display: none;
}

nav .menu>li {
  display: inline-block;
  position: relative;
  padding: 0;
  opacity: 0;
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1) .6s;
}

nav.ready .menu>li {
  opacity: 1
}

nav.ready .menu>li:nth-child(2) {
  transition-delay: .75s;
}

nav.ready .menu>li:nth-child(3) {
  transition-delay: .9s;
}

nav.ready .menu>li:nth-child(4) {
  transition-delay: 1.05s;
}

nav .menu>li:not(:last-child) {
  margin-right: 60px;
}

nav .menu>li>span, nav .menu>li>a {
  text-decoration: none;
  display: block;
  color: #F8F8F8;
  cursor: pointer;
  padding: 15px;
  border-radius: 6px;
  background: rgba(55, 55, 55, 0);
  transition: all .3s cubic-bezier(0.83, 0, 0.17, 1);
}

nav .menu>li>span:hover, nav .menu>li>a:hover {
  background: rgba(55, 55, 55, .05);
}

nav.fix .menu>li>span, nav.fix .menu>li>a, nav.about .menu>li>span, nav.about .menu>li>a {
  color: #373737;
}

nav .menu>li>ul {
  list-style-type: none;
  position: absolute;
  top: 54px;
  left: 0;
  padding: 15px 0 0;
  border-radius: 6px;
  border: 2px solid #0C4B76;
  background: #F8F8F8;
  pointer-events: none;
  opacity: 0;
  transform-origin: top left;
  transform: rotate(-1.5deg) scale(.84) translate(-15px, 15px);
  transition: all .15s cubic-bezier(0.83, 0, 0.17, 1);
}

nav .menu>li>ul:before {
  content: "";
  position: absolute;
  top: -8px;
  left: 30px;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background: #F8F8F8;
  border: solid #0C4B76;
  border-width: 2px 0 0 2px;
  transform: rotate(45deg);
}

nav .menu>li:hover>ul {
  pointer-events: all;
  opacity: 1;
  transform: rotate(0deg) scale(1) translate(0, 0);
}

nav .menu>li>ul.solutions-menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

nav .menu>li>ul>li {
  width: 216px;
  padding: 15px;
  margin: 0 15px 15px;
  border-radius: 13px;
  vertical-align: top;
  background: rgba(55, 55, 55, 0);
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1);
}

nav .menu>li>ul>li:hover {
  background: rgba(55, 55, 55, .05);
}

nav .menu>li>ul>li>a {
  color: #373737;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

nav .menu>li>ul>li>a>span {
  font-size: 12px;
  font-weight: 500;
  display: block;
}

nav .menu>li>ul>li>a>img {
  width: 6px;
  display: inline-block;
  margin-left: 9px;
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1);
}

nav .menu>li>ul>li:hover>a>img {
  margin-left: 15px;
}

nav .demo-box {
  opacity: 0;
  transform: scale(.84);
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1) 1.2s;
}

nav.ready .demo-box {
  opacity: 1;
  transform: scale(1);
}

nav .demo-box .demo {
  display: block;
  background: #0C4B76;
  border-radius: 17px;
  line-height: 59px;
  padding: 0 60px;
  color: #F8F8F8;
  text-decoration: none;
  position: relative;
  transition: all .3s cubic-bezier(0.83, 0, 0.17, 1);
}

nav.book .demo-box .demo {
  display: none;
}

nav .demo-box .demo:hover {
  background: transparent;
}

nav .demo::after {
  content: "";
  display: block;
  border-radius: 17px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(180deg, #087ACC 0%, #179CF0 100%);
}

nav.fix .demo {
  background: transparent;
}

nav .mobile-btn {
  display: none;
  background: transparent;
  border: 0;
  cursor: pointer;
}

nav .mobile-btn span {
  display: block;
  width: 20px;
  height: 4px;
  background: #F8F8F8;
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1);
}

nav.fix .mobile-btn span, nav.about .mobile-btn span, nav.book .mobile-btn span {
  background: #373737;
}

nav.fix.open .mobile-btn span {
  background: #F8F8F8;
}

nav .mobile-btn span:nth-child(2) {
  width: 26px;
}

nav .mobile-btn span:not(:last-child) {
  margin-bottom: 4px;
}

nav.open .mobile-btn.open span:first-child {
  transform: rotate(-45deg) translateY(11px);
}

nav.open .mobile-btn.open span:nth-child(2) {
  opacity: 0;
}

nav.open .mobile-btn.open span:last-child {
  transform: rotate(45deg) translateY(-11px);
}

nav .mobile-menu {
  width: 100%;
  overflow: auto;
  display: none;
}

nav .mobile-menu>p:not(.copy) {
  color: #0C4B76;
  font-size: 14px;
  font-weight: 500;
  max-width: 210px;
  margin-top: 30px;
}

nav .mobile-menu .accordion__item {
  margin-top: 60px;
}

nav .mobile-menu .accordion__button {
  color: #F8F8F8;
  font-size: 18px;
  font-weight: 500;
}

nav .mobile-menu .accordion__button img {
  display: inline-block;
  margin-left: 45px;
}

nav .mobile-menu .accordion__panel a {
  display: block;
  color: #F8F8F8;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
  text-decoration: none;
}

nav .mobile-menu .links-container {
  display: flex;
  justify-content: space-between;
  padding-top: 45px;
  margin-top: 45px;
  border-top: 1px dotted #F8F8F8;
}

nav .mobile-menu .links-container .links a {
  display: block;
  color: #0C4B76;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 45px;
  text-decoration: none;
}

nav .mobile-menu .links-container .social a {
  margin-bottom: 45px;
  display: block;
}

nav .mobile-menu .copy {
  color: #F8F8F8;
  font-weight: 500;
  font-size: 9px;
  display: block;
  padding-bottom: 90px;
}

.section-landing {
  position: relative;
}

.section-landing .flip-a, .section-landing .flip-b {
  content: "";
  width: 108%;
  height: 128%;
  position: absolute;
  top: -28%;
  left: -4%;
  border-radius: 180px;
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
}

.section-landing .flip-a {
  background: linear-gradient(180deg, #087ACC 0%, #179CF0 100%);
  transform-origin: center center;
  transform: rotate(0deg);
  opacity: 0;
  overflow: hidden;
  z-index: -3;
}

.ready .section-landing .flip-a {
  opacity: 1;
  transform: rotate(5.85deg) translate3d(0, 0, 0);
  animation-name: flip-a;
  animation-duration: 15s;
  animation-delay: 3s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  -webkit-perspective: 1000;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

.section-landing .flip-a img {
  position: absolute;
  left: 30px;
  top: 100%;
  transform: rotate(-5.85deg) translate3d(0, 0, 0);
  width: 600px;
  transition: all 1.5s cubic-bezier(0.22, 1, 0.36, 1) 1.2s;
}

.ready .section-landing .flip-a img {
  top: calc(28% - 15px);
  animation-name: flip-a;
  animation-duration: 15s;
  animation-delay: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  -webkit-perspective: 1000;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

.section-landing .flip-b {
  background: #0C4B76;
  opacity: 0;
  transform-origin: center center;
  transform: rotate(0deg) translate3d(0, 0, 0);
  z-index: -4;
  animation-name: flip-b;
  animation-duration: 15s;
  animation-delay: 3s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  -webkit-perspective: 1000;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

.ready .section-landing .flip-b {
  opacity: 1;
  transform: rotate(-3.59deg);
}

@keyframes flip-a {
  from {
    transform: rotate(5.85deg) translate3d(0, 0, 0);
  }

  to {
    transform: rotate(-5.85deg) translate3d(0, 0, 0);
  }
}

@keyframes flip-b {
  from {
    transform: rotate(-3.59deg) translate3d(0, 0, 0);
  }

  to {
    transform: rotate(3.59deg) translate3d(0, 0, 0);
  }
}

.section-landing h1 {
  font-family: 'Canela';
  font-size: 48px;
  line-height: 54px;
  color: #FFF;
  margin-bottom: 15px;
  max-width: 500px;
  margin-top: 240px;
  transition-delay: .6s;
}

.section-landing h5 {
  font-weight: 600;
  font-size: 20px;
  color: #0C4B76;
  margin-top: 0;
  margin-bottom: 65px;
  max-width: 540px;
  transition-delay: .75s;
}

.section-landing p {
  font-weight: 600;
  font-size: 16px;
  color: #F8F8F8;
  max-width: 540px;
  margin-bottom: 20px;
  transition-delay: .9s;
}

.section-landing .spread {
  width: 100%;
  max-width: 462px;
  margin: 240px auto 60px;
  transition-delay: 1.05s;
}

.section-landing .spread::before, .section-landing .spread::after {
  border-radius: 46px;
  transition: all 3s cubic-bezier(0.22, 1, 0.36, 1) 1.35s;
}

.section-landing .spread::before {
  transform: scale(1) rotate(0deg);
}

.ready .section-landing .spread::before {
  transform: scale(1.08) rotate(-5.81deg);
}

.section-landing .spread::after {
  transform: rotate(0deg) translate(0px, 0px);
}

.ready .section-landing .spread::after {
  transform: rotate(5.82deg) translate(-24px, 24px);
}

.section-landing .image {
  width: 100%;
  display: block;
  overflow: hidden;
  border-radius: 46px;
}

.section-landing .image img {
  width: 100%;
}

.section-landing-mobile {
  display: none;
  margin-top: 60px;
  margin-bottom: 120px;
}

.section-landing .demo-box .demo{
text-decoration: none;
}

.section-landing button{
  height: 48px;
  width: 180px;
  border: 2px solid #0C4B76;
  box-sizing: border-box;
  border-radius: 17px;
  margin-bottom: 180px;
  display: block;
  background: #0C4B76;
  color: #F8F8F8;
  text-decoration: none;
  position: relative;
  transition: all .3s cubic-bezier(0.83, 0, 0.17, 1);
}

.section-landing .book{
  border-color: #2196F3;
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: normal;
  cursor: pointer;
}

.section-landing .book:hover{
  background: #0C4B76;
  border-color: #2196F3;
  border: 2px solid #0C4B76;
  color: white;
}

.section-screen .carousel-root {
  border: 8px solid #FFF;
  box-shadow: -8px -8px 19px rgba(0, 0, 0, .25);
  border-radius: 52px;
  margin-bottom: 180px;
}

.section-screen .carousel.carousel-slider {
  overflow: visible;
}

.section-screen .carousel .control-dots {
  top: -57px;
  right: 0;
  width: 189px;
}

.section-screen .carousel .control-dots .dot, .section-testimonial .carousel .control-dots .dot {
  width: 47px;
  height: 3px;
  background: #0C4B76;
  transition: all .3s cubic-bezier(0.22, 1, 0.36, 1);
  border-radius: 1.5px;
  box-shadow: none;
  opacity: 1;
}

.section-screen .carousel .control-dots .dot.selected, .section-testimonial .carousel .control-dots .dot.selected {
  background: #F8F8F8;
}

.section-screen .content {
  padding-top: 90px;
}

.section-screen .content p {
  font-size: 16px;
  font-weight: 500;
}

.section-screen .content h4 {
  font-family: 'Canela';
  font-size: 27px;
  opacity: 0.6;
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1);
}

.section-screen .content h4.active {
  opacity: 1;
}

.section-screen .content h4:last-of-type {
  margin-bottom: 90px;
}

.section-screen .content h4 img {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}

.section-screen .arrow {
  position: absolute;
  right: -15px;
  bottom: -240px;
}

.section-screen .arrow.listening {
  transform: rotate(0deg) translate(0, 750px) scale(1);
}

.section-screen-mobile {
  display: none;
  margin-bottom: 120px;
}

.section-screen-mobile p {
  font-size: 10px;
}

.section-screen-mobile h4 {
  font-family: 'Canela';
  font-weight: 700;
  font-size: 22px;
  position: relative;
}

.section-screen-mobile h4::before {
  content: "";
  display: block;
  width: 17px;
  height: 100%;
  background: linear-gradient(180deg, #087ACC 0%, #179CF0 100%);
  position: absolute;
  left: -30px;
  top: 0;
}

.section-screen-mobile .image {
  border: 8px solid #FFF;
  box-shadow: -8px -8px 19px rgba(0, 0, 0, .25);
  border-radius: 33px;
}

.section-screen-mobile .image img {
  width: 100%;
}

.section-solutions {
  margin-bottom: 180px;
}

.section-solutions .title h2 {
  font-family: 'Canela';
  font-size: 48px;
  margin-bottom: 0;
}

.section-solutions .title p {
  font-size: 20px;
  font-weight: 600;
  color: rgba(55, 55, 55, .5);
  margin-top: 6px;
  margin-bottom: 60px;
}

.section-solutions .aside {
  margin-bottom: 60px;
}

.section-solutions .aside>img {
  display: inline-block;
  width: 231px;
  margin-right: 15px;
}

.section-solutions .aside .content {
  display: inline-block;
  width: calc(100% - 261px);
  padding-right: 15px;
  vertical-align: top;
  position: relative;
  padding-bottom: 15px;
}

.section-solutions .aside .content::after {
  content: "";
  display: block;
  border-radius: 56px;
  opacity: 0;
  background: linear-gradient(180deg, #087ACC 0%, #179CF0 100%);
  position: absolute;
  width: 30%;
  height: 100%;
  left: -35%;
  top: 0;
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1) .6s;
}

.section-solutions .aside .content.reveal::after {
  width: 135%;
  opacity: .1;
}

.section-solutions .aside .content h6 {
  font-family: 'Canela';
  color: #0C4B76;
  font-size: 22px;
  margin-top: 15px;
  margin-bottom: 0;
}

.section-solutions .aside .content p {
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  min-height: 75px;
}

.section-solutions .aside .content a {
  font-weight: 600;
  font-size: 14px;
}

.section-solutions .aside .content a span {
  display: inline-block;
}

.section-solutions .aside .content a img {
  display: inline-block;
  margin-left: 9px;
  vertical-align: -3px;
}

.section-cases .container {
  justify-content: center;
}

.section-cases .title h2 {
  font-family: 'Canela';
  font-size: 48px;
  max-width: 496px;
  display: block;
  margin: 0 auto 0;
}

.section-cases .title p {
  font-size: 36px;
  font-weight: bold;
  color: rgba(55, 55, 55, .5);
  max-width: 660px;
  display: inline-block;
  margin: 150px auto 80px;
}

.section-cases .content {
  height: 330px;
  text-align: center;
  border: 1px solid rgba(55, 55, 55, .25);
  border-radius: 56px;
  margin-bottom: 60px;
}

.section-cases .content:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.3);
}

.section-cases .content .head {
  width: 100%;
  margin-bottom: 30px;
}

.section-cases .content .logo {
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.section-cases .content h2 {
  font-family: 'Canela';
  font-size: 36px;
  min-height: 50px;
  padding: 0 30px;
  margin-top: 50px;
  margin-bottom: 15px;
  opacity: 0;
  transform: translateY(60px);
  transition: all .6s cubic-bezier(0.22, 1, 0.36, 1) .6s;
}

.section-cases .content.reveal h2 {
  opacity: 1;
  transform: translateY(0);
}

.section-cases .content p {
  font-family: 'Poppins';
  font-weight: lighter;
  font-size: 14px;
  padding: 0 30px;
  margin-bottom: 30px;
  opacity: 0;
  transform: translateY(60px);
  transition: all .6s cubic-bezier(0.22, 1, 0.36, 1) .75s;
}

.section-cases .content.reveal p {
  opacity: 1;
  transform: translateY(0);
}

.section-cases .content a {
  font-weight: 500;
  padding: 0 30px;
  margin-bottom: 30px;
  display: block;
  opacity: 0;
  transform: translateY(60px);
  transition: all .6s cubic-bezier(0.22, 1, 0.36, 1) .9s;
}

.section-cases .content.reveal a {
  opacity: 1;
  transform: translateY(0);
}

.section-cases .content a span {
  display: inline-block;
}

.section-cases .content a img {
  display: inline-block;
  float: right;
  margin-top: 2px;
  opacity: 0;
  transform: translateX(-60px);
  transition: all .6s cubic-bezier(0.22, 1, 0.36, 1) 1.05s;
}

.section-cases .content.reveal a img {
  opacity: 1;
  transition: translateX(0);
}

.section-software{
  position: relative;
}

.mobile-view{
  display: none;
}

.section-software h1 {
  font-family: 'Canela';
  font-size: 48px;
  line-height: 54px;
  color: black;
  margin-bottom: 15px;
  max-width: 600px;
  margin-top: 80px;
  transition-delay: .6s;
}

.section-software h5 {
  font-weight: 600;
  font-size: 20px;
  color: black;
  margin-top: 0;
  margin-bottom: 75px;
  max-width: 540px;
  transition-delay: .75s;
}

.section-software p {
  font-weight: normal;
  font-size: 16px;
  color: black;
  max-width: 600px;
  transition-delay: .8s;
}

.section-software ul li{
  font-weight: normal;
  font-size: 16px;
  color: black;
  max-width: 600px;
  transition-delay: 1s;
}

.section-software a{
  color: rgba(55, 55, 55, .5);
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  background: linear-gradient(180deg, #087ACC 0%, #179CF0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  transition-delay: 1s;
}

.section-software .title p {
  font-size: 36px;
  font-weight: bold;
  color: rgba(55, 55, 55, .5);
  max-width: 700px;
  display: inline-block;
  margin: 15px auto 70px;
}

.section-software .spread {
  width: 100%;
  max-width: 462px;
  margin: 80px auto 120px;
  transition-delay: 1.05s;
}

.section-software .spread::before, .section-software .spread::after {
  border-radius: 46px;
  transition: all 3s cubic-bezier(0.22, 1, 0.36, 1) 1.35s;
}

.section-software .spread::before {
  transform: scale(1) rotate(0deg);
}

.ready .section-software .spread::before {
  transform: scale(1.08) rotate(-5.81deg);
}

.section-software .spread::after {
  transform: rotate(0deg) translate(0px, 0px);
}

.ready .section-software .spread::after {
  transform: rotate(5.82deg) translate(-24px, 24px);
}

.section-software .image {
  width: 100%;
  display: block;
  overflow: hidden;
  border-radius: 46px;
}

.section-software .image img {
  width: 100%;
}

.section-software-mobile {
  display: none;
  margin-top: 60px;
  margin-bottom: 120px;
}

.section-industries{
  margin-bottom: 180px;
}

.section-industries .title p {
  font-size: 36px;
  font-weight: bold;
  color: rgba(55, 55, 55, .5);
  max-width: 700px;
  display: inline-block;
  margin: 150px auto 70px;
}

.section-industries .listening{
  display: flex;
}

.section-industries h4{
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 24px;
  color: black;
  margin-top: auto;
  margin-bottom: auto;
  transition-delay: .75s;
}

.section-industries img{
  margin-left: 100px;
  margin-right: 20px;
}

.section-industries .content{
  margin-top: 25px;
  margin-bottom: 25px;
}

.section-achievements {
  margin-top: 120px;
  margin-bottom: 180px;
}

.section-achievements p {
  display: block;
}

.section-achievements h1{
  font-size: 36px;
  font-weight: bold;
}

.section-achievements .point {
  display: inline-block;
  min-width: 240px;
  text-align: center;
}

.section-achievements .point h2 {
  font-family: 'Canela';
  font-size: 48px;
  margin-bottom: 0;
}

.section-achievements .point h2 span:first-child {
  font-size: 43px;
}

.section-achievements .point h2 span:nth-child(2) {
  font-size: 35px;
}

.section-achievements .point>span {
  font-weight: 600;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
}
.section-achievements .point img{
  margin: 15px;
}

.section-learn {
  background: rgba(55, 55, 55, .05);
  padding-top: 120px;
  padding-bottom: 120px;
  margin-bottom: 240px;
}

.section-learn .col-40p, footer .col-40p, .about-leadership .col-40p {
  padding-left: 0;
  padding-right: 15px;
}

.section-learn .col-60p, footer .col-60p, .about-leadership .col-60p {
  padding-right: 0;
  padding-left: 15px;
}

.section-learn h2 {
  font-family: 'Canela';
  font-size: 48px;
}

.section-learn p {
  font-size: 16px;
  margin-bottom: 60px;
  font-weight: 600;
}

.section-learn a, .section-learn-mobile a {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}

.section-learn a span, .section-learn-mobile a span {
  display: inline-block;
}

.section-learn a img, .section-learn-mobile a img {
  display: inline-block;
  vertical-align: -3px;
  margin-left: 15px;
}

.section-learn .spread {
  width: 100%;
  max-width: 651px;
  margin: 0 auto;
  transform: translate(0, 0) scale(.84) rotate(0deg);
}

.section-learn .spread.reveal {
  transform: translate(0, 0) scale(1) rotate(0deg);
}

.section-learn .spread::after, .section-learn .spread::before {
  border-radius: 46px;
  opacity: 0;
  transform: translate(0, 0) scale(1) rotate(0deg);
  transition: all .6s cubic-bezier(0.22, 1, 0.36, 1) 0.6s;
}

.section-learn .spread.reveal::after {
  opacity: 1;
  transform: translate(0, 0) scale(1) rotate(4.08deg);
}

.section-learn .spread.reveal::before {
  opacity: 1;
  transform: translate(0, 0) scale(1) rotate(-4.83deg);
}

.section-learn .video {
  width: 100%;
  overflow: hidden;
  border-radius: 46px;
  position: relative;
}

.section-learn .video img {
  width: 100%;
}

.section-learn .video .play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 58.5px;
  cursor: pointer;
}

.section-learn>img {
  width: 100%;
}

.section-learn-mobile {
  display: none;
}

.section-learn-mobile>img {
  width: 100%;
}

.section-testimonial-round>img {
  width: 100%;
}

.section-testimonial-round>img:first-child {
  display: none;
}

.section-testimonial {
  padding-top: 180px;
  padding-bottom: 180px;
  position: relative;
}

.section-testimonial h2 {
  font-family: 'Canela';
  font-size: 48px;
  color: #F8F8F8;
  text-align: center;
  display: block;
  max-width: 496px;
  width: 100%;
  margin: 0 auto;
}

.section-testimonial .box {
  width: 100%;
  max-width: 900px;
  margin: 90px auto 90px;
}

.section-testimonial .carousel.carousel-slider {
  overflow: visible;
}

.section-testimonial .carousel .control-dots {
  bottom: -120px;
}

.section-testimonial .carousel .slide .testimonial img {
  display: inline-block;
  width: 40%;
}

.section-testimonial .testimonial {
  display: flex;
  justify-content: space-between;
}

.section-testimonial .testimonial .image {
  margin-top: 50px;
}

.section-testimonial .testimonial .image img {
  border-radius: 50%;
}

.section-testimonial .testimonial .content {
  max-width: 60%;
  text-align: left;
  display: inline-block;
  vertical-align: top;
}

.section-testimonial .testimonial .content .head {
  margin-top: 0;
  font-size: 16px;
  font-weight: 600;
  color: #F8F8F8;
}

.section-testimonial .testimonial .content h6 {
  color: #0C4B76;
  font-size: 16px;
  margin-bottom: 0;
  font-family: 'Canela';
}

.section-testimonial .testimonial .content .sub {
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 0px;
  color: #F8F8F8;
}

.section-testimonial .testimonial .content .sub2 {
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: #F8F8F8;
}


.section-testimonial .quot {
  position: absolute;
}

.section-testimonial .quot.left {
  top: 180px;
  left: 60px;
}

.section-testimonial .quot.right {
  bottom: 90px;
  right: 60px;
}

/* About page */
.about-landing .container:first-child {
  position: relative;
}

.about-landing .head h1 {
  font-family: 'Canela';
  font-weight: bold;
  font-size: 48px;
  line-height: 54px;
  max-width: 540px;
  margin: 300px auto 30px;
  transition-delay: .6s;
}

.about-landing .head p {
  font-size: 20px;
  font-weight: 600;
  max-width: 540px;
  margin: 15px auto 240px;
  transition-delay: .75s;
}

.about-landing .exactly h5 {
  font-weight: 500;
  font-size: 20px;
  margin-top: 15px;
}

.about-landing .exactly p {
  font-weight: 500;
  max-width: 420px;
  font-size: 14px;
}

.about-landing .stats .stat {
  display: inline-block;
  width: 50%;
}

.about-landing .stats .stat p {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
}

.about-landing .stats .stat h3 {
  font-family: 'Canela';
  font-weight: bold;
  font-size: 40px;
  margin-top: 0;
}

.about-landing .square, .book-demo .square {
  display: block;
  position: absolute;
  border-radius: 42px;
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1);
}

.about-landing .square:nth-of-type(2), .book-demo .square:nth-of-type(2) {
  background: linear-gradient(180deg, #087ACC 0%, #179CF0 100%);
  width: 557px;
  height: 542px;
  transform: translateX(600px) rotate(28deg);
  left: 93%;
  top: 180px;
  z-index: 1;
  opacity: 0;
  transition-delay: .9s;
}

.ready .about-landing .square:nth-of-type(2), .ready.book-demo .square:nth-of-type(2) {
  opacity: 1;
  transform: translateX(0px) rotate(-28deg);
}

.about-landing .square:nth-of-type(3), .book-demo .square:nth-of-type(3) {
  background: #0C4B76;
  width: 556px;
  height: 543px;
  transform: translateX(600px) rotate(16deg);
  left: 93%;
  top: 180px;
  z-index: 0;
  opacity: 0;
  transition-delay: 1.05s;
}

.book-demo .square:nth-of-type(2), .book-demo .square:nth-of-type(3) {
  top: -420px;
  left: 84%;
}

.ready .about-landing .square:nth-of-type(3), .ready.book-demo .square:nth-of-type(3) {
  opacity: 1;
  transform: translateX(0px) rotate(-16deg);
}

.about-landing .square:nth-of-type(4), .book-demo .square:nth-of-type(4) {
  background: linear-gradient(180deg, #087ACC 0%, #179CF0 100%);
  width: 318px;
  height: 310px;
  left: -300px;
  top: 180px;
  opacity: 0;
  transform: translateX(-400px) rotate(28deg);
  z-index: 1;
  transition-delay: 1.2s;
}

.ready .about-landing .square:nth-of-type(4), .ready.book-demo .square:nth-of-type(4) {
  opacity: 1;
  transform: translateX(0px) rotate(-28deg);
}

.about-landing .square:nth-of-type(5), .book-demo .square:nth-of-type(5) {
  background: #0C4B76;
  width: 318px;
  height: 310px;
  left: -300px;
  top: 180px;
  transform: translateX(-400px) rotate(16deg);
  z-index: 0;
  opacity: 0;
  transition-delay: 1.35s;
}

.book-demo .square:nth-of-type(4), .book-demo .square:nth-of-type(5) {
  top: auto;
  bottom: -90px;
  left: -150px;
}

.ready .about-landing .square:nth-of-type(5), .ready.book-demo .square:nth-of-type(5) {
  opacity: 1;
  transform: translateX(0px) rotate(-16deg);
}

.about-values {
  padding-top: 90px;
  padding-bottom: 120px;
}

.about-values h2 {
  font-family: 'Canela';
  font-weight: bold;
  font-size: 48px;
  max-width: 420px;
  margin: 30px auto 15px;
}

.about-values .full-col p {
  font-weight: 600;
  font-size: 20px;
  color: rgba(55, 55, 55, .5);
  margin: 0 auto 90px;
  max-width: 420px;
}

.about-values .col-3 {
  position: relative;
  margin-bottom: 60px;
}

.about-values .col-3::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(2.75deg);
  border-radius: 17px;
  background: rgba(55, 55, 55, .11);
  z-index: -2;
}

.about-values .value {
  background: #FFF;
  border-radius: 17px;
}

.about-values .value img {
  display: inline-block;
  margin: 12px;
}

.about-values .value .content {
  display: inline-block;
  width: calc(100% - 136px);
  margin-left: 12px;
  vertical-align: top;
  padding-right: 15px;
}

.about-values .value .content h6 {
  font-family: 'Canela';
  font-weight: bold;
  font-size: 29px;
  margin-top: 15px;
  margin-bottom: 6px;
}

.about-values .value .content p {
  font-weight: 500;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

.about-leadership {
  padding-top: 90px;
  padding-bottom: 180px;
}

.about-leadership h2 {
  font-family: 'Canela';
  font-size: 48px;
  margin-bottom: 0;
}

.about-leadership .head {
  font-weight: 600;
  color: rgba(55, 55, 55, .5);
  font-size: 20px;
}

.about-leadership .member {
  display: inline-block;
  width: calc(33.333333333333% - 40px);
  position: relative;
  vertical-align: top;
}

.about-leadership .member:not(:last-child) {
  margin-right: 60px;
}

.about-leadership .member>img {
  width: 100%;
  height: auto;
}

.about-leadership .member h5 {
  font-weight: 600;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 0;
}

.about-leadership .member p {
  font-weight: 500;
  font-size: 12px;
  margin-top: 0;
}

.about-leadership .member a {
  display: block;
  position: absolute;
  left: 15px;
  top: 30px;
  opacity: 0;
  transition: all .6s cubic-bezier(0.83, 0, 0.17, 1);
}

.about-leadership .member p.reveal+a {
  opacity: 1;
}

.section-join {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
}

.section-join .container {
  max-width: 600px;
}

.section-join h2 {
  font-family: 'Canela';
  font-size: 48px;
  color: #F8F8F8;
}

.section-join p {
  color: #F8F8F8;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
}

.section-join a {
  display: inline-block;
  background: #F8F8F8;
  border-radius: 17px;
  line-height: 59px;
  padding: 0 60px;
  text-decoration: none;
  margin-top: 60px;
}

.section-join>img {
  position: absolute;
  width: 539px;
  left: -15px;
  top: 0;
}

/* Product page */
.section-laptop {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section-laptop h2 {
  font-family: 'Canela';
  font-weight: 700;
  font-size: 48px;
}

.section-laptop .col-2>p:first-of-type {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 60px;
}

.section-laptop .col-2>p:nth-of-type(2) {
  font-size: 13px;
  font-weight: 500;
  margin-top: 90px;
  margin-bottom: 30px;
}

.section-laptop .points>div {
  display: inline-block;
  width: calc(50% - 15px);
}

.section-laptop .points>div:nth-of-type(odd) {
  padding-right: 15px;
}

.section-laptop .points>div:nth-of-type(even) {
  padding-left: 15px;
}

.section-laptop .points h3 {
  font-family: 'Canela';
  font-size: 37px;
  font-weight: bold;
  margin-bottom: 15px;
}

.section-laptop .spread {
  display: block;
  max-width: 435px;
  width: 100%;
  padding-bottom: 75%;
  margin-left: 90px;
  margin-top: 90px;
}

.section-laptop .spread::before, .section-laptop .spread::after {
  border-radius: 46px;
}

.section-laptop .laptop img {
  width: 150%;
  margin-left: -25%;
  margin-top: -450px;
}

.section-laptop .logos {
  align-items: center;
  justify-content: flex-start;
}

.section-laptop .logos img {
  display: inline-block;
}

.section-laptop .logos img:first-child {
  height: 26px;
  margin-right: 60px;
  vertical-align: 3px;
}

.section-laptop .logos img:nth-child(2) {
  height: 14px;
  margin-right: 60px;
  vertical-align: 6px;
}

.section-laptop .logos img:nth-child(3) {
  height: 30px;
  margin-right: 60px;
}

.section-laptop .logos img:nth-child(4) {
  height: 27px;
}

.section-product .container:nth-child(odd) {
  flex-direction: row-reverse;
}

.section-product h3 {
  font-family: 'Canela';
  font-weight: bold;
  font-size: 27px;
  max-width: 450px;
  margin: 150px auto 15px;
}

.section-product p {
  font-size: 16px;
  max-width: 450px;
  margin: 0 auto 90px;
}

.section-product .image, .section-solution .image {
  display: flex;
  margin: 0 auto;
  max-width: 335px;
  height: 400px;
  align-items: center;
  width: 100%;
  position: relative;
}

.section-product .image::after, .section-solution .image::after {
  content: "";
  display: block;
  width: 100%;
  height: 75%;
  border-radius: 34px;
  position: absolute;
  left: 0;
  top: 12.5%;
  background: rgba(55, 55, 55, .05);
  transform: rotate(-5.85deg);
}

.section-product .image img, .section-solution .image img {
  width: 100%;
}

.section-plug {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section-plug h2, .section-solution h2 {
  font-family: 'Canela';
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 15px;
}

.section-plug p, .section-solution p {
  font-size: 20px;
  font-weight: 600;
  color: rgba(55, 55, 55, .5);
  max-width: 615px;
  margin: 15px auto 90px;
}

.section-plug .logos {
  max-width: 615px;
  margin: 0 auto;
}

.section-plug .logos img {
  display: inline-block;
  margin-bottom: 60px;
}

.section-plug .logos img:first-child {
  height: 45px;
  margin-right: 75px;
  vertical-align: 15px;
}

.section-plug .logos img:nth-child(2) {
  height: 68px;
  margin-right: 75px;
}

.section-plug .logos img:nth-child(3) {
  height: 68px;
}

.section-plug .logos img:nth-child(4) {
  height: 57px;
  margin-right: 75px;
}

.section-plug .logos img:nth-child(5) {
  height: 39px;
  vertical-align: 8px;
  margin-right: 75px;
}

.section-plug .logos img:nth-child(6) {
  height: 60px;
  vertical-align: 5px;
}

.section-laptop.solution-mod p {
  max-width: 450px;
}

.section-solution {
  margin-bottom: 120px;
}

.section-solution .container:nth-child(3) {
  flex-direction: row-reverse;
}

.section-solution .container .col-3:last-child {
  margin-right: 60px;
}

.section-solution .head {
  max-width: 330px;
  margin-top: 60px;
}

.section-solution .content {
  position: relative;
  margin-top: 90px;
}

.section-solution .content::after {
  content: "";
  display: block;
  width: 240%;
  height: 128%;
  border-radius: 56px;
  background: linear-gradient(180deg, #087ACC 0%, #179CF0 100%);
  opacity: .1;
  position: absolute;
  top: 0;
  left: -50%;
}

.section-solution .content h2 {
  display: inline-block;
  font-size: 84px;
  opacity: .12;
  margin-top: 30px;
  margin-left: -30px;
  margin-top: 15px;
}

.section-solution .content>div {
  display: inline-block;
  width: calc(100% - 60px);
  float: right;
}

.section-solution .content h5 {
  font-family: 'Canela';
  font-size: 20px;
  color: #0C4B76;
  margin-bottom: 0;
}

.section-solution .content p {
  font-size: 15px;
  font-weight: 400;
  color: #373737;
}

/* Book a demo */
.book-demo {
  padding-top: 120px;
}

.book-demo h2 {
  font-size: 48px;
  font-weight: bold;
  font-family: 'Canela';
  margin-top: 150px;
  max-width: 420px;
}

.book-demo p {
  font-size: 22px;
  max-width: 420px;
  font-weight: 600;
}

.book-demo form .control {
  margin-bottom: 30px;
  display: block;
}

.book-demo form .control:first-child {
  margin-top: 90px;
}

.book-demo form .control input, .book-demo form .control select, .book-demo form .control textarea {
  line-height: 72px;
  padding: 0 15px;
  width: calc(100% - 30px);
  max-width: 320px;
  font-size: 16px;
  font-family: 'Poppins';
  color: #373737;
  background: #FFF;
  border: 1px solid #989898;
  border-radius: 4px;
  margin: 0;
}

.book-demo form .control input::placeholder, .book-demo form .control textarea::placeholder {
  color: #373737;
}

.book-demo form .control select {
  -webkit-appearance: none;
  width: 100%;
  max-width: 350px;
}

.book-demo form .control.select {
  position: relative;
  max-width: 350px;
}

.book-demo form .control.select::after {
  content: "";
  display: block;
  background: url('./assets/select-arrow.svg') no-repeat center center;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 15px;
  top: 23px;
}

.book-demo form .control textarea {
  max-width: 420px;
  min-height: 156px;
  line-height: 24px;
  padding: 18px 15px;
}

.book-demo form button {
  border: 0;
  background: #0C4B76;
  border-radius: 17px;
  line-height: 59px;
  padding: 0 60px;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin: 0 0 120px 0;
}

.book-demo .copy {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 60px;
}

.book-demo .copy a {
  float: right;
  color: #373737;
  text-decoration: none;
}

.book-demo .copy a:first-child {
  margin-left: 30px;
}

/* Footer */
footer:not(.footer-mobile) {
  background: #0C4B76;
  padding-top: 60px;
}

footer.book {
  display: none;
}

footer .logo {
  margin-top: 60px;
  margin-bottom: 60px;
}

footer .below-logo {
  font-size: 14px;
  color: #F8F8F8;
  font-weight: 500;
  max-width: 336px;
  margin-bottom: 90px;
}

footer ul {
  list-style-type: none;
  padding-left: 0;
}

footer ul li {
  width: 33.333333333333%;
  display: inline-block;
  vertical-align: top;
}

footer ul li h6 {
  font-weight: 500;
  font-size: 12px;
  color: #F8F8F8;
  opacity: .5;
}

footer ul li a {
  font-weight: 500;
  font-size: 15px;
  color: #F8F8F8;
  display: block;
  text-decoration: none;
  margin-bottom: 30px;
}

footer .social a {
  display: inline-block;
  width: 44px;
  margin-right: 12px;
  margin-bottom: 60px;
}

footer .faq {
  max-width: 900px;
}

footer .faq h2 {
  font-family: 'Canela';
  font-size: 48px;
  color: #F8F8F8;
  margin-bottom: 90px;
}

footer .faq h6 {
  font-family: 'Canela';
  font-size: 23px;
  margin-bottom: 0;
  color: #F8F8F8;
}

footer .faq p {
  font-size: 16px;
  margin-bottom: 90px;
  color: #F8F8F8;
}

footer:not(.footer-mobile) .copy {
  font-weight: 500;
  font-size: 12px;
  color: #F8F8F8;
  padding-top: 45px;
  padding-bottom: 45px;
}

footer:not(.footer-mobile) .copy a {
  float: right;
  text-decoration: none;
  color: #F8F8F8;
}

footer:not(.footer-mobile) .copy a:first-of-type {
  margin-left: 60px;
}

.footer-mobile {
  display: none;
  background: #0C4B76;
  padding-top: 30px;
}

.footer-mobile .copy {
  font-size: 9px;
  font-weight: 500;
  color: #F8F8F8;
  width: 100%;
}

.footer-mobile .copy a {
  float: right;
  margin-top: -3px;
}

.footer-mobile .copy a:not(:first-child) {
  margin-right: 12px;
}

.footer-mobile .copy a img {
  width: 22.5px;
}

.footer-mobile>a {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #F8F8F8;
  line-height: 66px;
  text-decoration: none;
  text-align: center;
}

@media (max-width: 1024px) {
  .col-2 {
    flex: 1 0 100%;
  }

  .col-2:first-child {
    padding-right: 0;
  }

  .col-2:last-child {
    padding-left: 0;
  }

  .col-3 {
    flex: 1 0 100%;
    max-width: 100%;
  }

  .col-3:not(:last-child) {
    margin-right: 0;
  }

  .col-40p {
    flex: 1 0 100%;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .col-60p {
    flex: 1 0 100%;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .full-col {
    width: 100%;
  }

  nav {
    overflow: hidden;
  }

  nav::after {
    pointer-events: all;
  }

  nav .menu {
    display: none;
  }

  nav .demo-box {
    display: none;
  }

  nav .mobile-btn {
    display: block;
  }

  nav .mobile-menu {
    display: block;
    pointer-events: all;
  }

  .section-landing .container {
    flex-direction: column;
  }

  .section-lading .book{
    display: none;
  }

  .section-landing .demo-box{
    display: none;
  }

  .section-landing .demo-box .demo{
    display: none;
  }

  .section-landing h1 {
    margin-top: 180px;
    font-size: 42px;
    line-height: 48px;
  }

  .section-landing h5 {
    font-size: 16px;
  }

  .section-landing p {
    display: none;
  }

  .section-landing .spread {
    margin-top: 30px;
  }

  .section-landing .flip-a {
    border-radius: 15px;
    width: 150%;
    left: -25%;
  }

  .section-landing .flip-a img {
    width: 50%;
  }

  .section-landing .flip-b {
    display: none;
  }

  .section-landing-mobile {
    display: block;
  }

  .section-screen {
    display: none;
  }

  .section-screen-mobile {
    display: block;
  }

  .section-solutions .container {
    flex-direction: column;
  }

  .section-solutions .title h2 {
    font-size: 42px;
  }

  .section-solutions .title p {
    font-size: 10px;
  }

  .section-solutions .aside .content {
    width: calc(100% - 60px);
    padding: 0 30px 60px;
  }

  .section-solutions .aside .content.reveal::after {
    width: 100%;
    height: 150%;
    left: 0;
    top: -50%;
  }

  .section-cases .container:nth-last-child(2), .section-cases .container:last-child {
    flex-direction: column;
  }

  .section-cases .title h2 {
    text-align: left;
    font-size: 42px;
  }

  .section-cases .content h2 {
    text-align: center;
    font-size: 30px;
  }

  /* .section-cases .title p {
    font-size: 10px;
    text-align: left;
  } */

  .section-cases .content {
    margin-bottom: 60px;
  }

  .section-achievements .point {
    min-width: 210px;
  }

  .section-industries h4{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    transition-delay: .75s;
  }

  .section-industries img{
    margin-left: 30px;
    margin-right: 20px;
  }

  .section-learn {
    background: transparent;
    padding-bottom: 60px;
    margin-bottom: 0;
  }

  .section-learn .container {
    flex-direction: column;
  }

  .section-learn h2 {
    font-size: 42px;
  }

  .section-learn p {
    font-size: 10px;
  }

  .section-learn a {
    display: none;
  }

  .section-learn>img {
    display: none;
  }

  .section-learn-mobile {
    display: block;
  }

  .section-learn-mobile .container {
    margin-bottom: 90px;
  }

  .section-testimonial-round>img:first-child {
    display: block;
  }

  .section-testimonial-round>img:last-child {
    display: none;
  }

  .section-testimonial {
    padding-top: 60px;
  }

  .section-testimonial h2 {
    text-align: left;
    margin-top: 100px;
    font-size: 42px;
  }

  .section-testimonial .testimonial {
    flex-direction: column;
  }

  .section-testimonial .testimonial .image{
    align-content: flex-start;
    width: 80%;
    margin-left: -80px;
  }

  .section-testimonial .testimonial .content {
    max-width: 100%;
    margin-top: 30px;
  }

  .section-testimonial .testimonial .content .head {
    font-size: 14px;
  }

  .section-testimonial .carousel .control-dots {
    display: inline-block;
    width: 189px;
  }

  .section-testimonial .quot {
    width: 120px;
    height: auto;
  }

  .section-testimonial .quot.left {
    top: 30px;
  }

  .about-landing .container {
    flex-direction: column;
  }

  .about-landing .head h1 {
    font-size: 40px;
    line-height: 48px;
  }

  .about-landing .head p {
    font-size: 10px;
    margin-top: 60px;
  }

  .about-landing .square:nth-child(2), .about-landing .square:nth-child(3) {
    top: 60px;
    left: 108%;
  }

  .about-landing .square:nth-child(4), .about-landing .square:nth-child(5) {
    top: 300px;
    left: -330px;
  }

  .about-landing .exactly p {
    margin-bottom: 90px;
  }

  .about-landing .stats .stat p {
    font-size: 10px;
  }

  .about-values {
    padding-bottom: 0px;
  }

  .about-values .container {
    flex-direction: column;
  }

  .about-values .full-col h2 {
    font-size: 40px;
  }

  .about-values .full-col p {
    font-size: 10px;
  }

  .about-values .value .content h6 {
    font-size: 20px;
  }

  .about-leadership .container {
    flex-direction: column;
  }

  .about-leadership h2 {
    font-size: 40px;
  }

  .about-leadership .head {
    font-size: 10px;
    margin-bottom: 60px;
  }

  .about-leadership .member {
    width: 100%;
    margin-bottom: 60px;
  }

  .about-leadership .member:not(:last-child) {
    margin-right: 0;
  }

  .section-laptop {
    padding-top: 60px;
  }

  .section-laptop .spread {
    margin-left: 0;
    margin-top: 0;
    padding-bottom: 100%;
  }

  .section-laptop .laptop img {
    margin-top: -390px;
  }

  .section-laptop .container {
    flex-direction: column-reverse;
  }

  .section-laptop.solution-mod .container {
    flex-direction: column;
  }

  .section-laptop h2 {
    font-size: 42px;
  }

  .section-laptop .col-2>p:first-of-type {
    font-size: 10px;
  }

  .section-laptop .points p {
    font-size: 14px;
  }

  .section-laptop .logos img {
    margin-bottom: 30px;
  }

  .section-product .container, .section-product .container:nth-child(odd) {
    flex-direction: column;
  }

  .section-product h3 {
    margin-top: 0;
  }

  .section-plug h2, .section-solution h2 {
    font-size: 42px;
  }

  .section-plug p, .section-solution p {
    font-size: 10px;
  }

  .section-plug .logos {
    max-width: 360px;
  }

  .section-plug .logos img {
    display: inline-block;
  }

  .section-plug .logos img:first-child {
    margin-right: 30px;
  }

  .section-plug .logos img:nth-child(2) {
    margin-right: 0;
  }

  .section-plug .logos img:nth-child(3) {
    margin-left: 9px;
    margin-right: 24px;
  }

  .section-plug .logos img:nth-child(4) {
    margin-right: 0;
  }

  .section-plug .logos img:nth-child(5) {
    margin-right: 45px;
  }

  .section-software .container{
    flex-direction: column;
  }

  .section-software .container{
    width: 75%;
  }

  .desktop-view{
    display: none;
  }

  .mobile-view{
    display: contents;
  }

  .section-software h1{
    font-size: 42px;
  }

  .section-software a{
    font-size: 16px;
  }

  .section-software p{
    display: none;
  }

  .section-software ul{
    display: none;
  }

  .section-software li{
    display: none;
  }

  .section-solution .container:not(:first-child) {
    flex-direction: column;
  }

  .section-solution .container .col-3:last-child {
    margin-right: 0;
  }

  .section-solution .head {
    margin: 0 auto;
  }

  .section-solution .content {
    margin-top: 0;
  }

  .section-solution .content::after {
    height: 240%;
    width: 100%;
    left: 0;
    top: -50%;
  }

  .section-solution .content h2 {
    margin-left: 15px;
    vertical-align: top;
  }

  .section-solution .content p {
    max-width: 100%;
  }

  .section-solution .content>div {
    width: calc(100% - 105px);
    margin-left: 15px;
    float: none;
  }

  .section-solution .image {
    margin-top: -60px;
  }

  .book-demo {
    padding-top: 60px;
  }

  .book-demo .container {
    flex-direction: column;
  }

  .book-demo h2 {
    font-size: 40px;
  }

  .book-demo p {
    font-size: 10px;
  }

  .book-demo .square:nth-child(2), .book-demo .square:nth-child(3) {
    left: 60%;
  }

  .book-demo .square:nth-child(4), .book-demo .square:nth-child(5) {
    top: 300px;
    left: -330px;
  }

  .book-demo .copy a {
    float: none;
    display: inline-block;
    margin-top: 30px;
  }

  .book-demo .copy a:first-child {
    margin-left: 0;
    margin-right: 30px;
  }

  footer:not(.footer-mobile) {
    display: none;
  }

  .footer-mobile {
    display: block;
  }
}
